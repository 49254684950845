<template>
    <div class="dialog-box">
        <div class="close" @click="handleClose">
            <i class="iconfont iconguanbi"></i>
        </div>
        <div class="content_box">
            <div class="content">
                <div class="head_go_name">
                    {{ title }}
                </div>
                <div class="message_box">
                    <div class="inp">
                        <el-input
                            type="textarea"
                            :rows="8"
                            placeholder="请输入"
                            v-model="value"
                            v-if="type === 1"
                        >
                        </el-input>
                        <el-input
                            v-else
                            placeholder="请输入"
                            v-model="value"
                            style="margin-top: .1rem;"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="btns">
                <el-button class="btn btn2" @click="handleClose">
                    取消
                </el-button>
                <el-button
                    class="btn btn1"
                    @click="handleSave"
                >
                    确认
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sys-update',
    data() {
        return {
            type: '',
            title: '',
            value: '',
        };
    },
    props: {
        extr: Object,
    },
    created() {
        if (this.extr) {
            this.type = this.extr.type;
            this.value = this.extr.value;
            this.title = this.extr.title;
        }
    },
    methods: {
        handleClose() {
            this.$parent.hide();
        },
        handleSave() {
            this.extr.saveFun(this.value, this.type);
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.dialog-box
    position: relative;
    z-index: 11;
    width 100%;
    height 100%
    .close
        position absolute;
        right 0.1rem;
        top 0.1rem;
        width: 0.25rem;
        height: 0.25rem;
        padding-top 0.01rem;
        display flex;
        justify-content center;
        align-items center;
        cursor pointer;
        .iconguanbi
            font-size 0.14rem;
            color #000;
    .content_box
        box-sizing border-box
        width 100%;
        height 100%
        padding 0.2rem 0.4rem;
        background #fff;
        .content
            height calc(100% - .6rem)
            .head_go_name
                margin-bottom .1rem
                text-align center
            .file_box
                height 1.8rem
            .message_box
                height 2rem
                .title
                    color #999999
                    font-size .18rem
                    margin-bottom .1rem
        .btns
            height .6rem
            display flex;
            justify-content space-around;
            align-items center;
            position absolute
            bottom 0
            left 50%
            transform translate(-50%)
            .btn
                display flex
                justify-content center;
                align-items center;
                width: 1.4rem;
                height: 0.4rem;
                border-radius: 1px;
                border: 1px solid #1577D2;
                color #1577D2;
                font-size 0.16rem;
                &.btn1
                    background: #1577D2;
                    border-radius: 1px;
                    color #fff;
</style>
